<div class="md:hidden bg-white" *ngIf="authService.user">
    <div class="flex border-b-2 border-gray-light text-gray-900">
        <a class="flex items-center justify-center w-4/12 px-1 py-2.5 tracking-wide sm:tracking-wider text-center leading-5 text-xs -mb-0.5 font-medium" [ngClass]="active == 'SD_library' ? 'border-b-2 border-primary text-primary' : 'border-b-2 border-transparent'" href="javascript:void(0);" [routerLink]="'/cre-templates'">
            <div>
                <span class="block sm:inline-block">Template&nbsp;</span>
                <span class="block sm:inline-block">Library</span>
            </div>
        </a>

        <a class="flex items-center justify-center w-4/12 px-1 py-2.5 tracking-wide sm:tracking-wider text-center leading-5 text-xs -mb-0.5 font-medium" [ngClass]="active == 'USER_templates' ? 'border-b-2 border-primary text-primary' : 'border-b-2 border-transparent'" href="javascript:void(0);" [routerLink]="'/user-templates'">
            <div>
                <span class="block sm:inline-block">{{authService.user.default_team ? 'Team' : 'My'}}&nbsp;</span>
                <span class="block sm:inline-block">Templates</span>
            </div>
        </a>

        <a class="flex items-center justify-center w-4/12 px-1 py-2.5 tracking-wide sm:tracking-wider text-center leading-5 text-xs -mb-0.5 font-medium" [ngClass]="active == 'USER_projects' ? 'border-b-2 border-primary text-primary' : 'border-b-2 border-transparent'" href="javascript:void(0);" [routerLink]="'/projects'">
            <div>
                <span class="block sm:inline-block">{{authService.user.default_team ? 'Team' : 'My'}}&nbsp;</span>
                <span class="block sm:inline-block">Projects</span>
            </div>
        </a>
    </div>
</div>